import React, { createContext, useEffect, useState } from 'react';

export const filterContext = createContext();

function ContextProvider({ children }) {
  const [claimId, setClaimId] = useState('');
  const [selectedItem, setSelectedItem] = useState({
    dos: true,
    patientName: true,
    dob: true,
    clinicName: true,
    provider: true,
    type: true,
    payor: true,
    // dueDate: true,
    // clarificationType: true,
    // owner: true,
    // status: true,
    result: true,
    actions: true,
  });
  const [headCells, setHeadCells] = useState([
    { id: 'dos', active: false, direction: 'desc', label: 'DOS', item: 'dos' },
    {
      id: 'patientName',
      active: false,
      direction: 'desc',
      label: 'Patient Name',
      item: 'patientName',
    },
    {
      id: 'dob',
      active: false,
      direction: 'desc',
      label: 'DOB',
      item: 'patientDob',
    },
    {
      id: 'clinicName',
      active: false,
      direction: 'desc',
      label: 'Clinic Name',
      item: 'clinicName',
    },
    {
      id: 'provider',
      active: false,
      direction: 'desc',
      label: 'Provider',
      item: 'providerName',
    },
    {
      id: 'type',
      active: false,
      direction: 'desc',
      label: 'Type',
      item: 'appointmentType',
    },
    {
      id: 'payor',
      active: false,
      direction: 'desc',
      label: 'Payor',
      item: 'payor',
    },
    // { id: "dueDate", label: "Due Date" },
    // { id: "clarificationType", label: "Clarification Type" },
    // { id: "owner", label: "Owner" },
    // { id: "status", label: "Status" },
    {
      id: 'result',
      active: false,
      direction: 'desc',
      label: 'Result',
      item: 'result',
    },
    // { id: "actions", label: "Actions" },
  ]);

  useEffect(() => {
    if (localStorage.getItem('selectedItem') !== null) {
      const data = JSON.parse(localStorage.getItem('selectedItem'));
      setSelectedItem(data);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedItem', JSON.stringify(selectedItem));
  }, [selectedItem]);

  return (
    <filterContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        headCells,
        setHeadCells,
        claimId,
        setClaimId,
      }}
    >
      {children}
    </filterContext.Provider>
  );
}

export default ContextProvider;
