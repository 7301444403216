import React from 'react';
import { connect } from 'react-redux';
import { login, loginInfo } from 'Redux/EligibilityApis/eligibility.actions';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Input from 'Components/Input';
//import primroseLogo from "../../assets/images/primrose-brand-logo-sm-0.5x.png";
import trilliumLogo from '../../assets/Trillium-health-logo.png';
import './style.css';
import { useState, useEffect } from 'react';

function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const handleLogin = (e) => {
    e.preventDefault();
    props.login({
      clinicId: 0,
      userName: username,
      password: password,
    });
  };

  useEffect(() => {
    localStorage.removeItem('currentCoder');
    localStorage.removeItem('currentEligibility');
  }, []);

  useEffect(() => {
    if (props.eligibilityData.loginResponse) {
      let Response = props.eligibilityData.loginResponse.response;
      if (Response.responseCode === 0) {
        let token = Response.data.accessToken;
        localStorage.setItem('accessToken', token);
        localStorage.setItem('ownerId', Response.data.userId);
        localStorage.setItem('applicationId', 6);
        props.loginInfo();
      } else {
        setError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eligibilityData.loginResponse]);

  useEffect(() => {
    if (props.eligibilityData.cache) {
      let Response = props.eligibilityData.cache.response;
      if (Response.responseCode === 0) {
        localStorage.setItem('cache', JSON.stringify(Response.data));
        window.location.href = '/home';
      }
    }
  }, [props.eligibilityData.cache]);
  return (
    <div className="login__page scrollbarY--custom">
      <header className="login__header">
        <div className="login__header__bar">
          <a href="#0">
            <img
              src={trilliumLogo}
              alt="trillium-health-logo"
              className="login__header__logo"
            />
          </a>
        </div>
      </header>

      <main className="login__main">
        <section className="login__section">
          <div>
            <h1 className="login__grid__subheading">
              Eligibility Verification Tool
            </h1>
          </div>

          <div>
            <form onSubmit={handleLogin} className="login__form">
              <div className="login__inputField">
                <Input
                  required
                  margin="normal"
                  type="text"
                  label="Username"
                  variant="outlined"
                  className="login_input"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  error={error}
                />

                <Input
                  margin="normal"
                  required
                  type="password"
                  label="Password"
                  variant="outlined"
                  className="login_input"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  error={error}
                  helperText={error && 'Invalid Credentails'}
                />

                <div className="btnWrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="login__submitBtn"
                  >
                    LOGIN
                  </Button>
                </div>

                <div className="helper_text_wrap">
                  <div className="helper_text_large">Forgot your password?</div>
                  <div className="helper_text_small">
                    Contact account manager to reset password
                  </div>
                </div>
              </div>
            </form>
            <div className="footer_container">
              {/* <div className="box_container">
                <div className="box_heading">Primrose.health</div>
                <div className="list_item">117 Gemini Cir Ste#403</div>
                <div className="list_item">Birmingham, AL 35209</div>
                <div className="list_item">(205) 880-7575</div>
              </div>
              <div className="box_container">
                <div className="box_heading">Company</div>
                <div className="list_item">About us</div>
                <div className="list_item">Privacy policy</div>
                <div className="list_item">Blog</div>
                <div className="list_item">Contact us</div>
                <div className="list_item">Portfolio</div>
              </div>

              <div className="box_container">
                <div className="box_heading">Services</div>
                <div className="list_item">About us</div>
                <div className="list_item">Privacy policy</div>
                <div className="list_item">Blog</div>
                <div className="list_item">Contact us</div>
                <div className="list_item">Portfolio</div>
              </div>

              <div className="box_container">
                <div className="box_heading">Products</div>
                <div className="list_item">About us</div>
                <div className="list_item">Privacy policy</div>
                <div className="list_item">Blog</div>
                <div className="list_item">Contact us</div>
                <div className="list_item">Portfolio</div>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(login(values)),
  loginInfo: (values) => dispatch(loginInfo(values)),
});
Login.propTypes = {
  login: PropTypes.func,
  loginInfo: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
