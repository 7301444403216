import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
export function PrivateRoute({ component: Component,handleNext, authed, ...rest }) {
  const token = localStorage.getItem("accessToken")
  return (
    <Route
      {...rest}
      render={(props) =>
         token ? (
          <Component {...props}  />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
PrivateRoute.propTypes = {
  component: PropTypes.any,
  authed: PropTypes.any,
  location: PropTypes.any,
  handleNext:PropTypes.any
}
