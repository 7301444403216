import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
//import { ajax } from "rxjs/ajax";
import { ajax as UtilsAjax } from '../../Utils';
import { objectToUrl } from '../../Utils/ajax-utils';
import environment from '../../environments/environment';
import { Apis } from 'Redux/APIs';
import {
  APT_SAVE,
  CACHE,
  PAYER_SAVE,
  SEARCH_PATIENT,
  PROVIDER_LIST,
  PROVIDER_SAVE,
} from './apt.types';
import {
  AppointmentSaveResponse,
  getCacheResponse,
  payerSaveResponse,
  searchPatientResponse,
  providerListResponse,
  providerSaveResponse,
} from './apt.actions';
// import { Apis } from 'Redux/APIs'
const { baseUrl } = environment;
const { ClinicBaseUrl } = environment;
const AppointmentEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
AppointmentEpic.searchPatientApi = (action$) =>
  action$.pipe(
    ofType(SEARCH_PATIENT),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.appointment_searchPatient}/search?lastName=${action.payload.searchLastName}&dob=${action.payload.searchDob}&clinicId=${action.payload.clinicId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchPatientResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

AppointmentEpic.cacheApi = (action$) =>
  action$.pipe(
    ofType(CACHE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.appointment_cache}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCacheResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
AppointmentEpic.AppointmentSaveApi = (action$) =>
  action$.pipe(
    ofType(APT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.appointment_save}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => AppointmentSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
AppointmentEpic.payerSaveApi = (action$) =>
  action$.pipe(
    ofType(PAYER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.appointment_payerSave}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => payerSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

AppointmentEpic.providerSaveApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-clinic-service/v1/appointment/provider/${action.payload.appointmentId}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => providerSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
AppointmentEpic.providerListApi = (action$) =>
  action$.pipe(
    ofType(PROVIDER_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/trillium-clinic-service/v1/provider/list/all`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => providerListResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

export default AppointmentEpic;
