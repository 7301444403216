import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  logInfoSave,
  getEligibility,
  paHistory,
} from 'Redux/EligibilityApis/eligibility.actions';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import Input from 'Components/Input';
import DobPicker from 'Components/dobPicker';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from 'Components/Dropdown';
import Typography from '@material-ui/core/Typography';
import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import { toast } from 'react-hot-toast';
import EligibilityHistoDialog from 'Components/EligibilityHistoDialog';
import { dateTommddyyy } from '../../../../../libs/helper-functions/src/lib/TimeDateTimestamp';
import './style.css';
import { from } from 'rxjs';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function CallInfo(props) {
  let currentInfo = JSON.parse(sessionStorage.getItem('currentRow'));
  const classes = useStyles();
  const [results, setResults] = useState([]);
  const [payorPortalList, setPayorPortalList] = useState([]);
  const [logTypeList, setLogTypeList] = useState([]);
  const [insuranceTypeDtoList, setInsuranceTypeDtoList] = useState([]);
  const [paHistoryOpen, setPaHistoryOpen] = useState(false);
  const [form, setForm] = useState({
    activityDate: new Date(),
    activityId: 0,
    applicationId: 6,
    appointmentId: currentInfo.appointmentId ? currentInfo.appointmentId : 0,
    callRefNo: '',
    claimId: 0,
    coInsurance: null,
    copay: null,
    csrName: '',
    dueDate: '',
    duration: 0,
    insuranceId: 0,
    insuranceType: 1,
    medicalCodingId: 0,
    notes: '',
    outstandingDeductible: null,
    patientBalance: 0,
    patientId: 0,
    payorName: '',
    payorPortalId: 1,
    phoneNo: '',
    priorAuthId: 0,
    priorAuthNo: '',
    staffName: '',
    staffUserId: 0,
    staffProductivityCallerId: 0,
    staffProductivityOtherId: 0,
    status: 0,
    result: 0,
    typeId: 1,
    visitId: 0,
  });
  const [logName, setlogName] = useState('');
  const [dos, setDos] = useState('');
  const [apptType, setApptType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const { policyList } =
      JSON.parse(localStorage.getItem('eligibilityResponse')) || {};
    const insId = policyList?.[0]?.id;
    let ownerId = parseInt(localStorage.getItem('ownerId'));
    props.logInfoSave({
      ...form,
      insuranceId: form.insuranceId === 0 ? insId : form.insuranceId,
      staffUserId: ownerId,
      activityDate: dateToyyymmdd(form.activityDate),
    });
  };
  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));

    const updatedResults = cache.resultDtoList.map((x) => ({
      name: x.description,
      value: x.value,
    }));

    const updatedPayorPortalList = cache.payorPortalList.map((x) => ({
      name: x.description,
      value: x.value,
    }));

    const updatedLogTypeList = cache.logTypeList.map((x) => ({
      name: x.description,
      value: parseInt(x.value),
    }));

    const updatedInsuranceTypeDtoList = cache.insuranceTypeDtoList.map((x) => ({
      name: x.description,
      value: parseInt(x.value),
    }));

    // Update all states in one batch
    setResults(updatedResults);
    setPayorPortalList(updatedPayorPortalList);
    setLogTypeList(updatedLogTypeList);
    setInsuranceTypeDtoList(updatedInsuranceTypeDtoList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (props.eligibilityData.logInfoResponse) {
      let Response = props.eligibilityData.logInfoResponse.response;
      if (Response.responseCode === 0) {
        toast.success('Log Information Added');
        let data = JSON.parse(sessionStorage.getItem('currentRow'));
        if (data) {
          props.getEligibility({
            apptId: data.appointmentId,
          });
        }
        props.eligibilityData.logInfoResponse = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eligibilityData.logInfoResponse]);

  useEffect(() => {
    if (localStorage.getItem('eligibilityResponse')) {
      const data = JSON.parse(localStorage.getItem('eligibilityResponse'));
      setForm({
        ...form,
        copay: data.coPay ? parseFloat(data.coPay).toFixed(2) : '',
        coInsurance: data.coInsurance
          ? parseFloat(data.coInsurance).toFixed(2)
          : '',
        outstandingDeductible:
          typeof data.deductable === 'number'
            ? data.deductable >= 0
              ? parseFloat(data.deductable).toFixed(2)
              : -1
            : -1,
        patientBalance: data.patientBalance
          ? parseFloat(data.patientBalance).toFixed(2)
          : 0,
        result: data.resultId,
        appointmentId: data.appointmentId,
        insuranceId: data.policyList[0]?.id ? data.policyList[0]?.id : 0,
        patientId: data.patient.id,
        notes: '',
        insuranceType: data.coInsurance > 0 ? 2 : 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('eligibilityResponse')]);
  const paHistoryfn = (x) => {
    let data = JSON.parse(sessionStorage.getItem('currentRow'));
    props.paHistory(data.patientId);
    setPaHistoryOpen(true);
    setlogName(
      data.patientLName + ',' + data.patientFName + ' ' + data.patientMName
    );
    setDos(dateTommddyyy(data.appointmentTime));
    setApptType(data.appointmentType);
  };

  const handleInsurance = () => {
    const patientPolicy = JSON.parse(
      localStorage.getItem('patientPolicy') || '[]'
    );
    if (
      form.insuranceId === 0 &&
      (!patientPolicy.length || patientPolicy[0].payerName === '')
    ) {
      toast.error('Insurance not added');
    }
  };
  return (
    <div className={'callinfo_main_div'}>
      <form onSubmit={handleSubmit}>
        <div className="log_button">
          <Typography className={classes.heading}>Log</Typography>
          <div className="callinfo_buttons">
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
            <Button variant="outlined" color="primary">
              Cancel
            </Button>
            <Button variant="outlined" color="primary" onClick={paHistoryfn}>
              Appointment history
            </Button>
          </div>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <DobPicker
              required
              label="Date"
              value={form.activityDate}
              onChange={(e) =>
                setForm({ ...form, activityDate: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              required
              label="Type"
              variant="outlined"
              options={logTypeList}
              value={form.typeId}
              onChange={(e) =>
                setForm({ ...form, typeId: parseInt(e.target.value) })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              variant="outlined"
              type="text"
              label="Payor"
              margin="normal"
              value={form.payorName}
              onChange={(e) => setForm({ ...form, payorName: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              variant="outlined"
              options={payorPortalList}
              label="Portal"
              value={form.payorPortalId}
              onChange={(e) =>
                setForm({ ...form, payorPortalId: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              required
              variant="outlined"
              options={insuranceTypeDtoList}
              label="Copay/CoInsurance"
              value={form.insuranceType}
              onChange={(e) =>
                setForm({ ...form, insuranceType: e.target.value })
              }
            />
          </Grid>
          {form.insuranceType === 1 ? (
            <Grid item xs={3}>
              <Input
                variant="outlined"
                type="text"
                margin="normal"
                label="Copay Value"
                value={form.copay ? form.copay : ''}
                onChange={(e) => setForm({ ...form, copay: e.target.value })}
                onClick={handleInsurance}
              />
            </Grid>
          ) : (
            <Grid item xs={2}>
              <Input
                variant="outlined"
                type="text"
                margin="normal"
                label="CoInsurance Value"
                value={form.coInsurance ? form.coInsurance : ''}
                onChange={(e) =>
                  setForm({ ...form, coInsurance: e.target.value })
                }
                onClick={handleInsurance}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <Input
              variant="outlined"
              type="number"
              margin="normal"
              label="Outstanding Deductible"
              value={
                form.outstandingDeductible && form.outstandingDeductible >= 0
                  ? form.outstandingDeductible
                  : ''
              }
              onChange={(e) =>
                setForm({
                  ...form,
                  outstandingDeductible: e.target.value,
                })
              }
              onClick={() => handleInsurance()}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              className="patient_balance_field"
              variant="outlined"
              type="number"
              margin="normal"
              label="Patient Balance"
              value={form.patientBalance}
              disabled
              // onChange={(e) =>
              //   setForm({ ...form, patientBalance: e.target.value })
              // }
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown variant="outlined" label="Actions" />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              required
              variant="outlined"
              // options={results}
              options={[{ name: 'No Result', value: '0' }, ...results]}
              label="Result"
              value={form.result}
              onChange={(e) =>
                setForm({
                  ...form,
                  result: parseInt(e.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Notes"
              multiline
              maxRows={4}
              variant="outlined"
              value={form.notes}
              onChange={(e) => setForm({ ...form, notes: e.target.value })}
            ></Input>
          </Grid>
        </Grid>
      </form>
      <EligibilityHistoDialog
        open={paHistoryOpen}
        handleClose={() => setPaHistoryOpen(false)}
        name={logName}
        dos={dos}
        apptType={apptType}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
});

const mapDispatchToProps = (dispatch) => ({
  logInfoSave: (values) => dispatch(logInfoSave(values)),
  getEligibility: (values) => dispatch(getEligibility(values)),
  paHistory: (values) => dispatch(paHistory(values)),
});
CallInfo.propTypes = {
  logInfoSave: PropTypes.func,
  getEligibility: PropTypes.func,
  paHistory: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallInfo);
