export const Apis = {

  //Eligibility APIs
  eligibility_LoginApi: "trillium-clinic-service/v1/authentication/clinic/user/login",
  eligibility_List: "trillium-internal-service/v1/eligibilityPortal/list/range",
  eligibility_get: "trillium-internal-service/v1/eligibilityPortal",
  eligibility_icdsearch: "trillium-clinic-service/v1/icd/icdcode",
  eligibility_icdSave: "trillium-clinic-service/v1/icd/",
  eligibility_cptSearch: "trillium-clinic-service/v1/cliniccpt/cptcode",
  eligibility_loginfoSave: "trillium-internal-service/v1/log/",
  eligibility_cptSave: "trillium-clinic-service/v1/cliniccpt",
  eligibility_Save: "trillium-internal-service/v1/priorAuth",
  eligibility_Logininfo: 'trillium-internal-service/v1/common/priorAuth/cache',
  eligibility_fileUpload: "trillium-internal-service/v1/eligibilityPortal/upload",
  eligibility_downloadFile: "trillium-internal-service/v1/eligibilityPortal/",
  eligibility_logList: "trillium-internal-service/v1/log/list",
  eligibility_bulkUpdate: "trillium-internal-service/v1/eligibilityPortal/list",
  eligibility_rowUpdate: "trillium-internal-service/v1/eligibilityPortal/list",
  eligibility_icdKeywordSearch: "trillium-clinic-service/v1/icd/",
  eligibility_cptDelete: "trillium-internal-service/v1/priorAuth/cpt",
  eligibility_icdChild: "trillium-clinic-service/v1/icd/tree",
  eligibility_history: "trillium-clinic-service/v1/appointment/patient",
  eligibility_searchPayor: "trillium-clinic-service/v1/payer/search",
  eligibility_patientSave: "trillium-clinic-service/v1/patient/",
  eligibility_policyHolderSave: "trillium-clinic-service/v1/policyHolder/",
  eligibility_policySave: "trillium-clinic-service/v1/policy/",
  eligibility_mappingList: "trillium-internal-service/v1/common/external/mapping/list/",
  eligibility_saveMapping: "trillium-internal-service/v1/common/external/mapping/",


  //Appoinment APIs
  appointment_searchPatient: "trillium-clinic-service/v1/patient/",
  appointment_cache: "trillium-internal-service/v1/common/list",
  appointment_save: "trillium-clinic-service/v1/appointment/",
  appointment_payerSave: "trillium-clinic-service/v1/payer/",

}

