import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  getEligibility,
  getEligibilityResponse,
} from 'Redux/EligibilityApis/eligibility.actions';
import { getCache } from '../../Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Header from 'Components/Header';
import CallHistory from '../../Components/Callhistory';
import UploadInformation from '../../Components/UploadInfo';
import Loader from '../../Components/loader';
import AppLogout from 'Utils/inactive';
import { PatientDetails } from '../../../../../libs/patient-info/src';
import { PhysicianInformation } from '../../../../../libs/physician-info/src';
import CallInfo from './../../Components/CallInfo/index';
import { UploadList } from '../../../../../libs/upload-list/src/index';
import { PaymentSummary } from '../../../../../libs/payment-summary/src';
import { toast } from 'react-hot-toast';
import { PatientSelector } from '../../../../../libs/patient-selector/src';
import { ClaimSummary } from '../../../../../libs/claim-summary/src';
import { TabsPanel } from '../../../../../libs/tabs-panel/src';
import { dateTommddyyy } from '../../../../../libs/helper-functions/src/lib/TimeDateTimestamp';
import './EditStyle.css';

function EditContainer(props) {
  const [loader, setLoader] = useState(false);
  const [appointmentType, setAppoinmentType] = useState('');
  const [dos, setDos] = useState('');
  const [eligibilityInfo, setEligibilityInfo] = useState(null);
  const [policyHolderObject, setPolicyHolderObject] = useState(null);
  const [appointmentId, setAppointmentId] = useState('');
  const [apptIdArray, setApptIdArray] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [isLogAdded, setIsLogAdded] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [form, setForm] = useState({
    activityDate: new Date(),
    activityId: 0,
    claimId: 0,
    visitId: 0,
    applicationId: 7,
    callRefNo: '',
    coInsurance: '',
    copay: '',
    csrName: '',
    dueDate: '',
    duration: 0,
    eligibilityStatus: 0,
    insuranceId: 0,
    insuranceType: 0,
    medicalCodingId: 0,
    outstandingDeductible: 0,
    patientBalance: 0,
    patientId: 0,
    payorName: '',
    payorPortalId: 0,
    phoneNo: '',
    priorAuthId: 0,
    priorAuthNo: '',
    result: 0,
    staffName: '',
    staffUserId: parseInt(localStorage.getItem('ownerId')),
    staffProductivityCallerId: 0,
    staffProductivityOtherId: 0,
    status: 0,
    typeId: 0,
    notes: '',
  });
  const [policy, setPolicy] = useState([
    {
      active: 1,
      activeCoverageCleared: true,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 1,
    },
    {
      active: 1,
      activeCoverageCleared: false,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 2,
    },
  ]);
  const policyObjects = [
    {
      active: 1,
      activeCoverageCleared: true,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 1,
    },
    {
      active: 1,
      activeCoverageCleared: false,
      clarificationPresent: true,
      clinicId: 0,
      groupNo: '',
      id: 0,
      note: '',
      patientId: 0,
      payerAddressId: 0,
      payerId: 0,
      payerName: '',
      plan: '',
      policyHolderId: '0',
      policyHolderName: '',
      policyHolderRelation: 0,
      policyNo: '',
      policyType: 1,
      ranking: 2,
    },
  ];

  useEffect(() => {
    if (props.rowData) setRowData(props.rowData);
  }, [props.rowData]);
  useEffect(() => {
    if (props.eligibilityData.logInfoResponse) {
      let Response = props.eligibilityData.logInfoResponse.response;
      if (Response.responseCode === 0) {
        setIsLogAdded(!isLogAdded);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eligibilityData.logInfoResponse]);

  useEffect(() => {
    if (props.eligibilityData.eligibilityInfo) {
      let response = props.eligibilityData.eligibilityInfo.response;
      if (response.responseCode === 0) {
        // setIsLogAdded(!isLogAdded);
        setLoader(false);
        let data = response.data;
        // console.log(data);
        setEligibilityInfo(data);
        setAppoinmentType(data.appointmentType);
        setDos(data.dos);
        setAppointmentId(data.appointmentId);
        const rowData = JSON.parse(sessionStorage.getItem('rowData'));
        const updatedData = {
          ...rowData,
          appointmentId: data.appointmentId,
          appointmentTime: dateTommddyyy(data.dos),
          appointmentType: data.appointmentType,
        };
        sessionStorage.setItem('currentSelection', JSON.stringify(updatedData));
        sessionStorage.setItem('currentRow', JSON.stringify(updatedData));
        sessionStorage.setItem('rowData', JSON.stringify(updatedData));
      }
    }
  }, [props.eligibilityData.eligibilityInfo]);

  useEffect(() => {
    // console.log( JSON.parse(sessionStorage.getItem('currentRow')))
    if (sessionStorage.getItem('currentRow')) {
      let data = JSON.parse(sessionStorage.getItem('currentRow'));
      props.getEligibility({
        apptId: data.appointmentId,
      });
      // props.getCache(data.clinicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem('currentRow')]);

  useEffect(() => {
    if (props.eligibilityData.tableData) {
      let response = props.eligibilityData.tableData.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setApptIdArray(
          data.results.map((id) => {
            return id.appointmentId;
          })
        );
      }
    }
  }, [props.eligibilityData.tableData]);

  const findApptId = () => {
    return apptIdArray.find((element) => element === appointmentId);
  };

  const handleNext = () => {
    let currentEligibility = JSON.parse(
      localStorage.getItem('currentSelection')
    );
    let currentList = JSON.parse(localStorage.getItem('currentList'));
    let index = currentList.findIndex(
      (x) => x.appointmentId === currentEligibility.appointmentId
    );
    if (currentList[index + 1]) {
      props.getEligibility({
        apptId: currentList[index + 1].appointmentId,
      });
      sessionStorage.setItem('clinicId', currentList[index + 1].clinicId);
      setRowData(currentList[index + 1]);
      // setDisablePrev(false);
    } else {
      toast.error('List in end');
      // setDisableNext(true);
    }
  };

  const handlePrev = () => {
    let currentEligibility = JSON.parse(
      localStorage.getItem('currentSelection')
    );
    let currentList = JSON.parse(localStorage.getItem('currentList'));
    let index = currentList.findIndex(
      (x) => x.appointmentId === currentEligibility.appointmentId
    );
    if (currentList[index - 1]) {
      props.getEligibility({
        apptId: currentList[index - 1].appointmentId,
      });
      sessionStorage.setItem('clinicId', currentList[index - 1].clinicId);
      setRowData(currentList[index - 1]);
    } else {
      toast.error('List in end');
    }
  };

  const handleLogInfoSave = (data) => {
    props.logInfoSave(data);
  };

  // useEffect(() => {
  //   if (rowData) {
  //     props.getEligibility({
  //       apptId: rowData.appointmentId,
  //     });
  //     setRowData(rowData);
  //   }
  // }, [rowData]);

  // useEffect(() => {
  //   console.log('test');
  //   let currentEligibility = JSON.parse(
  //     localStorage.getItem('currentSelection')
  //   );
  //   // if (rowData) {
  //     props.getEligibility({
  //       apptId: currentEligibility.appointmentId,
  //     });
  //     // setRowData(rowData);
  //   // }
  // }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // localStorage.setItem("tabelPage", JSON.stringify(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <AppLogout>
      <div className="wrapper">
        <div className={isDrawerOpen ? 'leftTabOpen' : 'leftTabClosed'}>
          <div className="left_tab_inner_wrap">
            <PatientSelector
              totalCount={totalCount}
              setTotalCount={setTotalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowData={props.rowData}
              setRowData={props.setRowData}
              applicationId={6}
              isLogAdded={isLogAdded}
            />
          </div>
        </div>
        <div className={isDrawerOpen ? 'container_min' : 'container_max'}>
          <div className="headerComponent">
            <Header
              name={'Edit Eligibility'}
              backButton={true}
              // patientInfo={patientInfo}
              rowData={props.rowData}
              appointmentType={appointmentType}
              dos={dos}
              // payorName={payorName}
              prevButton={true}
              nextButton={true}
              handlePrev={handlePrev}
              handleNext={handleNext}
            />
          </div>
          {loader === false && (
            <div className="mainContainer">
              <div
                className={classNames('main_edit_section', {
                  main_edit_section_drawer_open: isDrawerOpen,
                })}
              >
                {/* <div className="procedure_info_container">
                  <ClaimSummary applicationId={7} rowInfo={props.rowData} />
                </div> */}
                {/* <div className="procedure_info_container">
                  <ProcedureSection applicationId={7} rowInfo={props.rowData} />
                </div> */}
                <div className="procedure_info_container">
                  <CallInfo rowData={props.rowData}/>
                </div>
                <div className="procedure_info_container">
                  <UploadInformation
                    applicationId={6}
                    rowInfo={props.rowData}
                  />
                </div>
              </div>
              <div
                className={classNames('rightTab', {
                  right_tab_drawer_open: isDrawerOpen,
                })}
              >
                <TabsPanel
                  rowData={props.rowData}
                  applicationId={6}
                  isLogAdded={isLogAdded}
                  setRowInfo={props.setRowData}
                />
              </div>
            </div>
          )}
        </div>
        {loader && <Loader />}
      </div>
    </AppLogout>
  );
}

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
});

const mapDispatchToProps = (dispatch) => ({
  getEligibility: (values) => dispatch(getEligibility(values)),
  getEligibilityResponse: (values) => dispatch(getEligibilityResponse(values)),
  getCache: (values) => dispatch(getCache(values)),
});
EditContainer.propTypes = {
  getEligibility: PropTypes.func,
  getEligibilityResponse: PropTypes.func,
  getCache: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditContainer);
