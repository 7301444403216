import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import DataTableFilter from '../../Components/dataTableFilter';
import DataTable from '../../Components/DataTable';
import Header from '../../Components/Header';
import { Appointment } from '../../../../../libs/appointment-popup/src';
// import Appointment from '../../Components/Appointment';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { eligibilityList } from '../../Redux/EligibilityApis/eligibility.actions';
import './style.css';
import { dateToyyymmdd } from '../../../../trillium-clinic-priorauth-ui/src/HelperFunctions/TimeDateTimestamp';

function TableContainer(props) {
  const [open, setOpen] = useState(false);
  const [checkBoxArray, setCheckBoxArray] = useState([]);
  const [patientData, setPatientData] = useState(false);

  useEffect(() => {
    localStorage.removeItem('currentEligibility');
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      props.AppointmentData.appointmentResponse &&
      props.AppointmentData.appointmentResponse.response
    ) {
      let Response = props.AppointmentData.appointmentResponse.response;
      if (Response.responseCode === 0) {
        toast.success('Appointment added sucessfully');
        setOpen(false);
      }
    }
  }, [props.AppointmentData.appointmentResponse]);

  const refreshHomeScreen = (params) => {
    let f = props.eligibilityData.filterForm;
    props.eligibilityList({
      startDate: f.startDate
        ? dateToyyymmdd(f.startDate)
        : dateToyyymmdd(new Date()),
      endDate: f.endDate ? dateToyyymmdd(f.endDate) : dateToyyymmdd(new Date()),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      start: 0,
      limit: 50,
      providerId: f.provider ? f.provider : '',
      result: f.result ? f.result : '',
      resultId: f.result ? f.result : '',
      clinicId: f.clinic ? f.clinic : '',
      patientId: f.patientId ? f.patientId : '',
      orderBy: '',
      orderByParameter: '',
      payorId: f.payorId ? f.payorId : '',
    });
  };

  return (
    <>
      <Header backButton={false} />
      <div className="data_table_filter_div">
        <DataTableFilter checkBoxArray={checkBoxArray} />
      </div>
      <div className="main_table">
        <DataTable
          priorDialog={(e) => setOpen(e)}
          checkBoxArray={(e) => setCheckBoxArray(e)}
          visible={(e) => props.visible(e)}
          passRowData={(e) => props.passRowData(e)}
          backButtonClicked={localStorage.getItem('backButtonClicked')}
        />
        {/* <Appointment
          applicationId={6}
          handleClose={() => handleClose()}
          open={open}
        /> */}
        <Appointment
          // apptCreated={apptCreated}
          refreshHomeScreen={refreshHomeScreen}
          handleClose={() => handleClose()}
          open={open}
          patientData={patientData}
          setPatientData={setPatientData}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  eligibilityList: (values) => dispatch(eligibilityList(values)),
});
TableContainer.propTypes = {
  eligibilityList: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
