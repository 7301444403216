import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useState } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import NoDataFound from 'assets/nodata-found.png';
import './style.css';

function LogDialog(props) {
  const [log, setLog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.eligibilityData.logList) {
      let response = props.eligibilityData.logList.response;
      if (response.responseCode === 0) {
        let lg = [];
        response.data.forEach((x) => {
          lg.push({
            note: x.note,
            date: x.date,
          });
        });
        setLog(lg.reverse());
        setIsLoading(false);
      }
      if (response.responseCode === 131) {
        setIsLoading(false);
      }
    }
  }, [props.eligibilityData.logList]);

  useEffect(() => {
    if (props.open) {
      setIsLoading(true);
    }
  }, [props.open]);

  return (
    <Dialog
      onClose={() => {
        setLog([]);
        props.handleClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      className="log_dialog"
    >
      <div className="log_content">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <div className="log_title_container">
              <p className="log_title">Log</p>
              <AssignmentIcon fontSize="small" color="primary" />
            </div>
          </Grid>
          <Grid item>
            <CloseIcon
              className="log_close_btn"
              onClick={() => {
                setLog([]);
                props.handleClose();
              }}
            />
          </Grid>
        </Grid>

        <div className="main_info">
          <div className="Patient_info">
            <div className="name_info">Patient Name</div>:
            <div className="name_info">{props.name}</div>
            <div className="name_info">, DOS :</div>
            <div className="name_info">{props.dos}</div>
          </div>

          {log.map((x, i) => (
            <div className="log_info" key={i}>
              {/* <div className="date_div">{x.date.substring(0, 10)}</div>- */}
              <div className="text_div">{x.note}</div>
            </div>
          ))}

          {!isLoading && log.length === 0 && (
            <div className="no_data_text">
              {' '}
              <img
                className="no_data_text"
                src={NoDataFound}
                alt={'NoDataFound'}
              />
            </div>
          )}

          {isLoading &&
            [1, 2, 3, 4, 5, 3, 3].map((x, i) => (
              <div className="log_info_loder" key={i}>
                <div className="load1">
                  {' '}
                  <Skeleton height={30} />{' '}
                </div>
                <div className="load2">
                  {' '}
                  <Skeleton height={30} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
});

const mapDispatchToProps = (dispatch) => ({});
LogDialog.propTypes = {};
export default connect(mapStateToProps, mapDispatchToProps)(LogDialog);
