import React from 'react';
import './style.css';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CallHistoryTable } from '../../../../../libs/call-history-table/src/index';
// import CallhistoryTable from "Components/CallHistoryTable";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function CallHistory(props) {
  const classes = useStyles();
  return (
    <div className={'callhistory_main_div'}>
      <Typography className={classes.heading}>Notes</Typography>
      <CallHistoryTable rowInfo={props.rowInfo} applicationId={6} />
    </div>
  );
}

export default CallHistory;
