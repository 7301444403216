import {
  APT_SAVE,
  APT_SAVE_RES,
  CACHE,
  CACHE_RES,
  PAYER_SAVE,
  PAYER_SAVE_RES,
  PROVIDER_LIST,
  PROVIDER_LIST_RES,
  PROVIDER_SAVE,
  PROVIDER_SAVE_RES,
  SEARCH_PATIENT,
  SEARCH_PATIENT_RES,
} from "./apt.types";

/**
   * @fileOverview Manages the action w.r.t types in the redux
   * @author Akhil Francis<akhil@trillium-technologies.com>
  
   * return(
   type and payload
   * )
   * @example export const actionName = (params) => ({
    type: Type of the action from login.type.js,
    payload: object - contains params,
  })
   */

/**
 * clinicSave-Action w.r.t CLINIC_SAVE(type) in the redux
 * @description - Action used to call the clinic save API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const searchPatient = (data) => ({
  type: SEARCH_PATIENT,
  payload: data,
});
/**
 * clinicSaveResponse - Action w.r.t CLINIC_SAVE_RES(type) in the redux
 * @description - Action used to get the response of clinic save API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const searchPatientResponse = (res) => ({
  type: SEARCH_PATIENT_RES,
  payload: res,
});
export const getCache = (res) => ({
  type: CACHE,
  payload: res,
});
export const getCacheResponse = (res) => ({
  type: CACHE_RES,
  payload: res,
});
export const AppointmentSave = (res) => ({
  type: APT_SAVE,
  payload: res,
});
export const AppointmentSaveResponse = (res) => ({
  type: APT_SAVE_RES,
  payload: res,
});
export const payerSave = (res) => ({
  type: PAYER_SAVE,
  payload: res,
});
export const payerSaveResponse = (res) => ({
  type: PAYER_SAVE_RES,
  payload: res,
});

export const providerSave = (res) => ({
  type: PROVIDER_SAVE,
  payload: res,
});
export const providerSaveResponse = (res) => ({
  type: PROVIDER_SAVE_RES,
  payload: res,
});
export const providerList = (res) => ({
  type: PROVIDER_LIST,
  payload: res,
});
export const providerListResponse = (res) => ({
  type: PROVIDER_LIST_RES,
  payload: res,
});
