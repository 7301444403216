import React, { useContext } from 'react';
import { connect } from 'react-redux';
import {
  eligibilityList,
  getEligibility,
  logList,
  bulkUpdate,
  paHistory,
} from 'Redux/EligibilityApis/eligibility.actions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  dateTommddyyy,
  dateToyyymmdd,
} from 'HelperFunctions/TimeDateTimestamp';
import Dropdown from 'Components/Dropdown';
import { useEffect } from 'react';
import { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import NoDataFound from 'assets/nodata-found.png';
import { toast } from 'react-hot-toast';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ListIcon from '@material-ui/icons/List';
import LogDialog from 'Components/logDialog';
import EligibilityHistoDialog from 'Components/EligibilityHistoDialog';
import { filterContext } from 'context/contextProvider';
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    borderTop: 'solid 1px #e0e0e0',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  logBtnTable: {
    cursor: 'pointer',
  },
}));
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: `lightgray`,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: `#139696`,
    },
  },
}));
const EnhancedTable = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [result, setResult] = useState([]);
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [logOpen, setLogOpen] = useState(false);
  const [logName, setlogName] = useState('');
  const [dos, setDos] = useState('');
  const [apptType, setApptType] = useState('');
  const [paHistoryOpen, setPaHistoryOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [orderByParameter, setOrderByParameter] = useState('');

  const { headCells, setHeadCells, selectedItem } = useContext(filterContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let result = [];
    cache.resultDtoList.forEach((x) => {
      result.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    setResult(result);
  }, []);

  useEffect(() => {
    let f = props.eligibilityData.filterForm;
    props.eligibilityList({
      startDate: f.startDate
        ? dateToyyymmdd(f.startDate)
        : dateToyyymmdd(new Date()),
      endDate: f.endDate ? dateToyyymmdd(f.endDate) : dateToyyymmdd(new Date()),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      start: page,
      limit: rowsPerPage,
      providerId: f.provider ? f.provider : '',
      result: f.result ? f.result : '',
      resultId: f.result ? f.result : '',
      clinicId: f.clinic ? f.clinic : '',
      patientId: f.patientId ? f.patientId : '',
      orderBy: orderBy,
      orderByParameter: orderByParameter,
      payorId: f.payorId ? f.payorId : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    props.eligibilityData.filterForm,
    props.eligibilityData.bulkUpdateResponse,
    props.AppointmentData.appointmentResponse,
    orderBy,
    orderByParameter,
    props.backButtonClicked,
  ]);

  useEffect(() => {
    let f = props.eligibilityData.filterForm;
    localStorage.setItem(
      'filterData',
      JSON.stringify({
        startDate: f.startDate
          ? dateToyyymmdd(f.startDate)
          : dateToyyymmdd(new Date().setFullYear(new Date().getFullYear())),
        endDate: f.endDate
          ? dateToyyymmdd(f.endDate)
          : dateToyyymmdd(new Date()),
        result: f.result ? f.result : null,
        providerId: f.provider ? f.provider : null,
        clinicId: f.clinic ? f.clinic : null,
      })
    );
    props.eligibilityList({
      startDate: f.startDate
        ? dateToyyymmdd(f.startDate)
        : dateToyyymmdd(new Date()),
      endDate: f.endDate ? dateToyyymmdd(f.endDate) : dateToyyymmdd(new Date()),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      start: page,
      limit: rowsPerPage,
      providerId: f.provider ? f.provider : '',
      result: f.result ? f.result : '',
      resultId: f.result ? f.result : '',
      clinicId: f.clinic ? f.clinic : '',
      patientId: f.patientId ? f.patientId : '',
      orderBy: orderBy,
      orderByParameter: orderByParameter,
      payorId: f.payorId ? f.payorId : '',
    });
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eligibilityData.filterForm]);

  useEffect(() => {
    if (props.eligibilityData.bulkUpdateResponse) {
      let response = props.eligibilityData.bulkUpdateResponse.response;
      if (response.responseCode === 0) {
        toast.success('Updation Success');
        setMainCheckbox(false);
        props.eligibilityData.bulkUpdateResponse = null;
      }
    }
  }, [props.eligibilityData.bulkUpdateResponse]);

  useEffect(() => {
    if (props.eligibilityData.tableData) {
      let response = props.eligibilityData.tableData.response;
      if (response.responseCode === 0) {
        setRows(response.data.results);
        // console.log(response.data.results);
        localStorage.setItem('allItems', JSON.stringify(response.data.results));
        setTotalCount(response.data.totalRecords);
      }
    }
  }, [props.eligibilityData.tableData]);

  const editClick = (data) => {
    props.getEligibility({
      apptId: data.appointmentId,
    });
    props.passRowData(data);
    // props.visible(true);
    const list = JSON.parse(localStorage.getItem('allItems'));
    list.forEach((item) => {
      if (parseInt(item.appointmentId) === parseInt(data.appointmentId)) {
        sessionStorage.setItem('currentSelection', JSON.stringify(item));
        sessionStorage.setItem('currentRow', JSON.stringify(item));
      }
    });
    const pageInfo = { page, rowsPerPage };
    sessionStorage.setItem('currentPageInfo', JSON.stringify(pageInfo));
  };

  useEffect(() => {
    const storedPageInfo = sessionStorage.getItem('currentPageInfo');
    if (storedPageInfo !== null) {
      const { page: storedPage, rowsPerPage: storedRowsPerPage } =
        JSON.parse(storedPageInfo);
      setPage(storedPage);
      setRowsPerPage(storedRowsPerPage);
      sessionStorage.removeItem('currentPageInfo');
    }
  }, []);

  useEffect(() => {
    if (props.eligibilityData.eligibilityInfo) {
      let response = props.eligibilityData.eligibilityInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        localStorage.setItem('currentEligibility', JSON.stringify(data));
        localStorage.setItem('currentSelection', JSON.stringify(data));
        localStorage.setItem('appointmentId', data.appointmentId);
        // props.visible(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eligibilityData.eligibilityInfo]);

  const handleLog = (x) => {
    props.logList({
      appointmentId: x.appointmentId,
    });
    setlogName(x.patientLName + ',' + x.patientFName + ' ' + x.patientMName);
    setDos(dateTommddyyy(x.appointmentTime));
    setApptType(x.appointmentType);
    setLogOpen(true);
  };

  const handleLogClose = () => {
    setLogOpen(false);
  };

  const handleCheckboxArray = (e) => {
    setMainCheckbox(e.target.checked);
    let r = [];
    rows.forEach((x) => {
      r.push({
        ...x,
        isSelected: e.target.checked,
      });
    });
    setRows([...r]);
    props.checkBoxArray([...r]);
  };

  const handleCheckbox = (e, i) => {
    let r = rows;
    r[i] = { ...r[i], isSelected: e.target.checked };
    setRows([...r]);
    props.checkBoxArray([...r]);
  };

  const updateResult = (e, x, i) => {
    let r = rows;
    r[i].resultId = e.target.value;
    setRows([...r]);
    props.bulkUpdate([
      {
        appointmentId: x.appointmentId,
        insuranceId: x.insuranceId,
        resultId: e.target.value,
      },
    ]);
  };

  const handleSort = (index) => {
    let ar = headCells;
    ar.forEach((y, i) => {
      if (i === index) {
        ar[i].active = true;
        ar[i].direction = ar[i].direction === 'asc' ? 'desc' : 'asc';
        setOrderByParameter(ar[i].item);
        setOrderBy(ar[i].direction === 'asc' ? 'desc' : 'asc');
      } else {
        ar[i].active = false;
        ar[i].direction = 'desc';
      }
    });
    setHeadCells([...ar]);
  };

  const handleEligibilityHistory = (x) => {
    props.paHistory(x.patientId);
    setPaHistoryOpen(true);
    setlogName(x.patientLName + ',' + x.patientFName + ' ' + x.patientMName);
    setDos(dateTommddyyy(x.appointmentTime));
    setApptType(x.appointmentType);

    const list = JSON.parse(localStorage.getItem('allItems'));
    list.forEach((item) => {
      if (parseInt(item.appointmentId) === parseInt(x.appointmentId)) {
        sessionStorage.setItem('currentSelection', JSON.stringify(item));
        sessionStorage.setItem('currentRow', JSON.stringify(item));
      }
    });
  };

  return (
    <div className={classes.root}>
      <LogDialog
        handleClose={() => handleLogClose()}
        open={logOpen}
        name={logName}
        dos={dos}
      />
      <EligibilityHistoDialog
        open={paHistoryOpen}
        handleClose={() => setPaHistoryOpen(false)}
        name={logName}
        dos={dos}
        apptType={apptType}
        // passRowData={props.passRowData()}
      />
      <AddCircleRoundedIcon
        color="primary"
        className="appointment_add_icon"
        onClick={() => props.priorDialog(true)}
      />
      <TableContainer className="eligibility_table_list">
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead className="dataTable_head">
            <TableRow>
              <TableCell
                className={'data_table_list_cell'}
                align={'left'}
                sortDirection={false}
              >
                <TableSortLabel
                  active={false}
                  direction={'asc'}
                  className="table_checkbox"
                >
                  <GreenCheckbox
                    color="primary"
                    size="small"
                    onChange={handleCheckboxArray}
                    checked={mainCheckbox}
                  ></GreenCheckbox>
                </TableSortLabel>
              </TableCell>
              {/* {headCells.map((x, i) => (
                <TableCell
                  key={i}
                  className={"data_table_list_cell"}
                  align={"left"}
                  sortDirection={false}
                >
                  <TableSortLabel active={false} direction={"asc"}>
                    {x.label}
                  </TableSortLabel>
                </TableCell>
              ))} */}
              {headCells &&
                headCells
                  .filter((x) => x.status === true)
                  .map((x, i) => (
                    <TableCell
                      key={i}
                      className={'data_table_list_cell'}
                      align={'left'}
                      sortDirection={true}
                    >
                      <TableSortLabel
                        onClick={(e) => handleSort(i)}
                        active={x.active}
                        direction={x.direction}
                      >
                        {x.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              <TableCell className="data_table_list_cell">
                <TableSortLabel>Actions</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((x, i) => (
              <TableRow key={i} hover role="checkbox">
                <TableCell className={'data_table_list_cell'} scope="row">
                  <div className="table_checkbox">
                    <GreenCheckbox
                      size="small"
                      checked={x.isSelected === true ? true : false}
                      onChange={(e) => handleCheckbox(e, i)}
                    ></GreenCheckbox>
                  </div>
                </TableCell>
                {selectedItem.dos && (
                  <TableCell className={'data_table_list_cell'} scope="row">
                    {x.appointmentTime}
                  </TableCell>
                )}
                {selectedItem.patientName && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {x.patientLName}
                    {',' + x.patientFName}
                    {',' + x.patientMName}
                  </TableCell>
                )}
                {selectedItem.dob && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {x.patientDob}
                  </TableCell>
                )}
                {selectedItem.clinicName && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {x.clinicName}
                  </TableCell>
                )}
                {selectedItem.provider && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {x.providerLName}
                    {',' + x.providerFName}
                    {',' + x.providerMName}
                  </TableCell>
                )}
                {selectedItem.type && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {x.appointmentType}
                  </TableCell>
                )}
                {selectedItem.payor && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {x.primaryPayorName}
                  </TableCell>
                )}
                {selectedItem.result && (
                  <TableCell className={'data_table_dropdown'} align="left">
                    <Dropdown
                      options={[{ name: 'No Result', value: '0' }, ...result]}
                      size="small"
                      value={x.resultId}
                      onChange={(e) => updateResult(e, x, i)}
                    />
                  </TableCell>
                )}

                <TableCell className={'data_table_list_cell'} align="left">
                  <div className="table_icon">
                    <Link to="/edit">
                      <EditIcon
                        className="table_editBtn"
                        fontSize="small"
                        onClick={() => editClick(x)}
                      />
                    </Link>
                    <AssignmentIcon
                      className="table_logBtn"
                      fontSize="small"
                      onClick={() => handleLog(x)}
                    />
                    <ListIcon
                      className="table_editBtn"
                      fontSize="small"
                      onClick={() => handleEligibilityHistory(x)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <div className="nodatafound">
            <img src={NoDataFound} alt={'NoDataFound'} />
          </div>
        )}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 12, 20, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  eligibilityList: (values) => dispatch(eligibilityList(values)),
  getEligibility: (values) => dispatch(getEligibility(values)),
  logList: (values) => dispatch(logList(values)),
  bulkUpdate: (values) => dispatch(bulkUpdate(values)),
  paHistory: (values) => dispatch(paHistory(values)),
});
EnhancedTable.propTypes = {
  eligibilityList: PropTypes.func,
  getEligibility: PropTypes.func,
  logList: PropTypes.func,
  bulkUpdate: PropTypes.func,
  paHistory: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
