import {
  Button,
  FormControl,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Input from '../../../Input/index';
import './style.css';
import { useEffect, useState, useMemo } from 'react';
import {
  arManagementList,
  claimSummarySave,
  coderList,
  eligibilityList,
  getClaimSummary,
  priorAuthList,
} from '../../../Redux/CoderApis/main.actions';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { DobPicker } from '../../../dob-picker/src';
import toast from 'react-hot-toast';
import { getCache } from '../../../Redux/Appointment/apt.actions';
import { Dropdown } from '../../../drop-down/src';

// import { ClaimSummaryComponent } from './claim-summary';

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

export function ClaimSummary(props) {
  const classes = useStyles();
  const [claimData, setClaimData] = useState({});
  const [isEdit, setIsEdit] = useState(true);
  const [dos, setDos] = useState('');
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState({});

  useEffect(() => {
    if (props.rowInfo) {
      setIsEdit(true);
      const claimId = props.rowInfo.claimId;
      props.getClaimSummary({
        claimId: claimId,
        applicationId: props.applicationId,
      });
      props.getCache(props.rowInfo.clinicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowInfo]);

  useEffect(() => {
    if (
      props.coderData &&
      props.coderData.claimSummary &&
      props.coderData.claimSummary.response
    ) {
      const data = props.coderData.claimSummary.response.data;
      if (data) {
        setClaimData(data);
        setDos(data.dos);
        setLocation(data.locationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.claimSummary]);

  useEffect(() => {
    if (
      props.appointmentData &&
      props.appointmentData.cache &&
      props.appointmentData.cache.response
    ) {
      const data = props.appointmentData.cache.response.data;
      if (data) {
        const locations = [...data.locationDtoList];
        const list = locations.map((location) => ({
          value: location.id,
          name: location.locationName,
        }));
        setLocationList(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.appointmentData.cache]);

  useEffect(() => {
    if (
      props.coderData &&
      props.coderData.claimSummarySave &&
      props.coderData.claimSummarySave.response
    ) {
      if (props.coderData.claimSummarySave.response.responseCode === 0) {
        const data = props.coderData.claimSummarySave.response.data;
        props.getClaimSummary({
          claimId: data.claimId,
          applicationId: props.applicationId,
        });
        const row = JSON.parse(sessionStorage.getItem('currentRow'));
        const currentSelection = JSON.parse(
          sessionStorage.getItem('currentSelection')
        );
        const list = JSON.parse(localStorage.getItem('currentList'));
        list.forEach((item) => {
          if (item.claimId === row.claimId) {
            item.dos = dos;
            item.locationId = claimData.locationId;
            item.locationName = claimData.locationName;
          }
        });
        localStorage.setItem('currentList', JSON.stringify(list));
        row.dos = dos;
        row.facilityId = claimData.locationId;
        row.locationName = claimData.locationName;
        sessionStorage.setItem('currentRow', JSON.stringify(row));
        if (currentSelection.claimId === row.claimId)
          sessionStorage.setItem('currentSelection', JSON.stringify(row));
        console.log(claimData);
        props.setRowInfo({
          ...props.rowInfo,
          dos: dos,
          facilityId: claimData.locationId,
          locationName: claimData.locationName,
        });
        const f = JSON.parse(localStorage.getItem('filterData'));
        const ownerId = localStorage.getItem('ownerId');
        if (f) {
          if (props.applicationId === 4) {
            props.priorAuthList({
              startDate: dateToyyymmdd(f.startDate),
              endDate: dateToyyymmdd(f.endDate),
              start: 0,
              limit: 100,
              ownerIds: ownerId.toString(),
            });
          }
          if (props.applicationId === 7) {
            props.coderList({
              startDate: f.startDate,
              endDate: f.endDate,
              start: 0,
              limit: 100,
              ownerId: ownerId.toString(),
            });
          }
          if (props.applicationId === 8) {
            props.arManagementList({
              startDate: f.startDate,
              endDate: f.endDate,
              start: 0,
              limit: 50,
              ownerId: ownerId.toString(),
            });
          }
          if (props.applicationId === 6) {
            props.eligibilityList({
              startDate: f.startDate,
              endDate: f.endDate,
              start: 0,
              limit: 50,
              ownerId: ownerId.toString(),
            });
          }
        }
        setIsEdit(true);
        toast.success('Updated successfully');
        props.coderData.claimSummarySave.response = null;
      } else {
        toast.error('Save Failed');
        props.coderData.claimSummarySave.response = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.claimSummarySave]);

  const handleCancel = () => {
    const data = props.coderData.claimSummary.response.data;
    // setLocation(data.locationNa);
    setDos(data.dos);
    setIsEdit(true);
  };

  const handleDate = (e) => {
    if (e.target.value === null) return;
    const formattedDate = e.target.value.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    setDos(formattedDate);
  };

  const convertDateFormat = (dateString) => {
    const splitDate = dateString.split('/');
    const newDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    return newDate;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dateFormatRegex =
      /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;
    const isValidDateFormat = dateFormatRegex.test(dos);
    if (isValidDateFormat)
      props.claimSummarySave({
        applicationId: props.applicationId,
        claimId: props.rowInfo.claimId,
        data: {
          claimId: props.rowInfo.claimId,
          clinicId: props.rowInfo.clinicId,
          locationId: claimData.locationId,
          locationName: claimData.locationName,
          dos: convertDateFormat(dos),
          visitId: props.rowInfo.visitId,
        },
      });
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    let ob = locationList.find((x) => x.value === e.target.value);
    setClaimData({
      ...claimData,
      locationId: e.target.value,
      locationName: ob.name,
    });
  };

  return (
    <div className={'claim_summary_main_div'}>
      <form onSubmit={handleSubmit}>
        <div className="claim_summary_header">
          <Typography className={classes.heading}>Claim Summary</Typography>
          {!isEdit && (
            <div className="claim_summary_header_buttons">
              <Button onClick={handleCancel} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Save
              </Button>
            </div>
          )}
          {isEdit && (
            <Button
              onClick={() => {
                setIsEdit(false);
              }}
              color="primary"
              variant="contained"
            >
              Edit
            </Button>
          )}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {isEdit ? (
              <Input
                className="input_material_field"
                // value={claimData?.dos ? claimData.dos : ' '}
                value={dos}
                variant="standard"
                label="DOS"
                InputProps={{ disableUnderline: true }}
              />
            ) : (
              <DobPicker
                label="DOS"
                onChange={(e) => handleDate(e)}
                value={dos}
                required
                variant="standard"
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <Input
              className="input_material_field"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              type="text"
              label="Patient"
              value={claimData?.patientName ? claimData?.patientName : ' '}
              margin="normal"
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              className="input_material_field"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              type="text"
              label="Rendering"
              value={
                claimData?.renderingProvider
                  ? claimData?.renderingProvider
                  : ' '
              }
              margin="normal"
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              className="input_material_field"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              type="text"
              label="Billing"
              value={
                claimData?.billingProvider ? claimData?.billingProvider : ' '
              }
              margin="normal"
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              className="input_material_field"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              type="text"
              label="Referring"
              value={
                claimData?.referingProvider ? claimData?.referingProvider : ' '
              }
              margin="normal"
            />
          </Grid>
          <Grid item xs={2}>
            <Input
              className="input_material_field"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              type="text"
              label="Service"
              value={
                claimData?.appointmentType ? claimData.appointmentType : ' '
              }
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            {isEdit ? (
              <div>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Location"
                  value={claimData.locationName ? claimData.locationName : ' '}
                  margin="normal"
                />
              </div>
            ) : (
              <Dropdown
                value={location}
                onChange={handleLocationChange}
                label="Location"
                variant={'outlined'}
                options={locationList}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <Input
              className="input_material_field"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              type="text"
              label="Billing"
              value={
                claimData?.billingAddresss ? claimData.billingAddresss : ' '
              }
              margin="normal"
            />
          </Grid>
          <Grid item xs={5}>
            <Input
              className="input_material_field"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              type="text"
              label="Status"
              value={claimData?.status ? claimData.status : ' '}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Billed"
                  value={
                    claimData?.billed >= 0
                      ? '$' +
                        Math.round((claimData.billed + Number.EPSILON) * 100) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Pri Paid"
                  value={
                    claimData?.priPaid >= 0
                      ? '$' +
                        Math.round((claimData.priPaid + Number.EPSILON) * 100) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Sec Paid"
                  value={
                    claimData?.secPaid >= 0
                      ? '$' +
                        Math.round((claimData.secPaid + Number.EPSILON) * 100) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Pt Paid"
                  value={
                    claimData?.patPaid >= 0
                      ? '$' +
                        Math.round((claimData.patPaid + Number.EPSILON) * 100) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Adjusted"
                  value={
                    claimData?.adjustment >= 0
                      ? '$' +
                        Math.round(
                          (claimData.adjustment + Number.EPSILON) * 100
                        ) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Pri Bal"
                  value={
                    claimData?.priBal >= 0
                      ? '$' +
                        Math.round((claimData.priBal + Number.EPSILON) * 100) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Sec Bal"
                  value={
                    claimData?.secBal >= 0
                      ? '$' +
                        Math.round((claimData.secBal + Number.EPSILON) * 100) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  className="input_material_field"
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  type="text"
                  label="Pt Bal"
                  value={
                    claimData?.patBal >= 0
                      ? '$' +
                        Math.round((claimData.patBal + Number.EPSILON) * 100) /
                          100
                      : ' '
                  }
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
  appointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  getClaimSummary: (values) => dispatch(getClaimSummary(values)),
  claimSummarySave: (values) => dispatch(claimSummarySave(values)),
  priorAuthList: (values) => dispatch(priorAuthList(values)),
  coderList: (values) => dispatch(coderList(values)),
  getCache: (values) => dispatch(getCache(values)),
  arManagementList: (values) => dispatch(arManagementList(values)),
  eligibilityList: (values) => dispatch(eligibilityList(values)),
});

ClaimSummary.propTypes = {
  getClaimSummary: PropTypes.func,
  getCache: PropTypes.func,
  claimSummarySave: PropTypes.func,
  priorAuthList: PropTypes.func,
  coderList: PropTypes.func,
  arManagementList: PropTypes.func,
  eligibilityList: PropTypes.func,
};

export const ClaimSummaryComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimSummary);
