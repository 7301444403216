import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getEligibility } from 'Redux/EligibilityApis/eligibility.actions';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import NoDataFound from 'assets/nodata-found.png';
import { Button } from '@material-ui/core';
import { dateTommddyyy } from '../../../../../libs/helper-functions/src/lib/TimeDateTimestamp';
import { Link } from 'react-router-dom';
import './style.css';

const useStyles = makeStyles((theme) => ({
  table: {
    border: 'solid 1px #e0e0e0',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
    display: 'flex',
    gap: '3rem',
  },
}));
function EligibilityHistoDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (props.eligibilityData.paHistory) {
      let response = props.eligibilityData.paHistory.response;
      if (response.responseCode === 0) {
        setRows(response.data);
      }
    }
  }, [props.eligibilityData.paHistory]);

  useEffect(() => {
    if (
      props.eligibilityData.eligibilityInfo &&
      props.eligibilityData.eligibilityInfo.response &&
      props.eligibilityData.eligibilityInfo.response.data
    ) {
      props.handleClose();
      let data = props.eligibilityData.eligibilityInfo.response.data;
      localStorage.setItem('appointmentId', data.appointmentId);
      props.eligibilityData.eligibilityInfo = null;
    }
  }, [props.eligibilityData.eligibilityInfo]);

  const editClick = (data) => {
    props.getEligibility({
      apptId: data.appointmentId,
    });
    const rowData = JSON.parse(sessionStorage.getItem('currentRow'));
    const updatedData = {
      ...rowData,
      appointmentId: data.appointmentId,
      appointmentTime: dateTommddyyy(data.dos),
      appointmentType: data.appointmentType,
      result:data.eligibilityStatus
    };

    sessionStorage.setItem('currentRow', JSON.stringify(updatedData));
    // sessionStorage.setItem('currentSelection', JSON.stringify(updatedData));
    // sessionStorage.setItem('rowData', JSON.stringify(updatedData));
  };

  return (
    <Dialog
      onClose={() => props.handleClose()}
      open={props.open}
      fullWidth={true}
      maxWidth={'lg'}
    >
      <div className="pa_history_main">
        <div className="appt_history_header">
          <Typography className={classes.heading}>
            Appointment history
            <div className="name_info">
              {props.name} | {props.dos} | {props.apptType}
            </div>
          </Typography>{' '}
          <div>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => props.handleClose()}
            >
              Cancel
            </Button>
          </div>
        </div>
        <TableContainer style={{ height: 600 }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {['DOS', 'Provider', 'Type', 'Payor', 'Status', 'Actions'].map(
                  (x, i) => (
                    <TableCell
                      key={i}
                      className={'data_table_list_cell'}
                      align={'left'}
                      sortDirection={false}
                    >
                      <TableSortLabel active={false} direction={'asc'}>
                        {x}
                      </TableSortLabel>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((x, i) => (
                <TableRow key={i}>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.appointmentDate
                      ? dateTommddyyy(x.appointmentDate + 'T12:00:00.000Z') +
                        ' ' +
                        x.appointmentTime
                      : ''}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.providerLName}
                    {x.providerFName ? ',' + x.providerFName : ''}
                    {x.providerMName ? ',' + x.providerMName : ''}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.appointmentType}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.payorName}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    {x.eligibilityStatus}
                  </TableCell>
                  <TableCell align="left" className={'data_table_list_cell'}>
                    <div className="table_icon">
                      <Link to="/edit">
                        <EditIcon
                          className="table_editBtn"
                          fontSize="small"
                          // onClick={() =>
                          //   editClick({
                          //     appointmentId: x.appointmentId,
                          //     eligibilityId: x.priorAuthId,
                          //   })
                          // }
                          onClick={() => editClick(x)}
                        />
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rows.length === 0 && (
            <div className="nodatafound">
              <img src={NoDataFound} alt={'NoDataFound'} />
            </div>
          )}
        </TableContainer>
        {/* <div className="pa_history_btns">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => props.handleClose()}
          >
            Cancel
          </Button>
        </div> */}
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
});

const mapDispatchToProps = (dispatch) => ({
  getEligibility: (values) => dispatch(getEligibility(values)),
});
EligibilityHistoDialog.propTypes = {
  getEligibility: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EligibilityHistoDialog);
