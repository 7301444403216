/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const SEARCH_PATIENT = "SEARCH_PATIENT";
export const SEARCH_PATIENT_RES = "SEARCH_PATIENT_RES";
export const CACHE = "CACHE";
export const CACHE_RES = "CACHE_RES";
export const APT_SAVE = "APT_SAVE";
export const APT_SAVE_RES = "APT_SAVE_RES";
export const PAYER_SAVE = "PAYER_SAVE";
export const PAYER_SAVE_RES = "PAYER_SAVE_RES";
export const PROVIDER_LIST = "PROVIDER_LIST";
export const PROVIDER_LIST_RES = "PROVIDER_LIST_RES";
export const PROVIDER_SAVE = "PROVIDER_SAVE";
export const PROVIDER_SAVE_RES = "PROVIDER_SAVE_RES";
