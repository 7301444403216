import React from 'react';
import './style.css';
import { FileUploaderDropzone } from '../../../../../libs/file-uploader-drop-zone/src/index';

function UploadInformation(props) {
  return (
    <div className="upload_main_div">
      <FileUploaderDropzone
        applicationId={props.applicationId}
        rowInfo={props.rowInfo}
      />
    </div>
  );
}

export default UploadInformation;
