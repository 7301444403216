import { ofType } from 'redux-observable';
import { switchMap, map, takeUntil,tap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ajax as UtilsAjax } from 'Utils';
import { objectToUrl } from 'Utils/ajax-utils';
import environment from '../../environments/environment';
import { Apis } from 'Redux/APIs';
import {
  BULK_UPDATE,
  LOG_INFO_SAVE,
  CPT_SAVE,
  CPT_SEARCH,
  DOWNLOAD,
  FILE_UPLOAD,
  GET_ELIGIBILITY,
  ICD_SAVE,
  ICD_SEARCH,
  LOGIN,
  LOGIN_INFO,
  LOGLIST,
  ELIGIBILITY_LIST,
  ELIGIBILITY_SAVE,
  REQUEST_CANCEL,
  ROW_UPDATE,
  ICD_KEYWORD_SEARCH,
  CPT_DELETE,
  ICD_CHILD,
  PA_HISTORY,
  SEARCH_PAYOR,
  PATIENT_SAVE,
  POLICYHOLDER_SAVE,
  POLICY_SAVE,
  MAPPING_LIST,
  SAVE_MAPPING,
} from './eligibility.types';
import {
  bulkUpdateResponse,
  logInfoSaveResponse,
  cptSaveResponse,
  cptSearchResponse,
  fileDownloadResponse,
  fileUploadResponse,
  getEligibilityResponse,
  icdSaveResponse,
  icdSearchResponse,
  loginInfoResponse,
  loginResponse,
  loglistResponse,
  eligibilityListResponse,
  eligibilitySaveRes,
  icdkeywordResponse,
  cptDeleteResponse,
  icdChildResponse,
  paHistoryRes,
  searchPayoRes,
  patientSaveRes,
  policyHolderSaveRes,
  policySaveRes,
  mappingListRes,
  saveMappingRes,
} from './eligibility.actions';
const { baseUrl } = environment;
const { ClinicBaseUrl } = environment;
const EligibilityEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
EligibilityEpic.login = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 6,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/${Apis.eligibility_LoginApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

// PriorAuthEpic.userSaveApi = (action$) =>
// action$.pipe(
//   ofType(SAVE_USER),
//   switchMap((action) =>
//   UtilsAjax({
//       url: `${baseUrl}/trillium-clinic-service/v1/user/`,
//       method: 'POST',
//       body: JSON.stringify(action.payload),
//     }).pipe(
//       map(
//         (response) => userSaveResponse(response),
//         takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
//       ),
//     ),
//   ),
// )

EligibilityEpic.eligibilityList = (action$) =>
  action$.pipe(
    ofType(ELIGIBILITY_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(`${ClinicBaseUrl}/${Apis.eligibility_List}`, action.payload),
        method: 'GET',
      }).pipe(
        map(
          (response) => eligibilityListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );


EligibilityEpic.getEligibility = (action$) =>
  action$.pipe(
    ofType(GET_ELIGIBILITY),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_get}/${action.payload.apptId}`,
        method: 'GET',
      }).pipe(
        tap((response) => {
          localStorage.setItem('eligibilityResponse', JSON.stringify(response.response.data));
        }),
        map((response) => getEligibilityResponse(response)),
        takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
      )
    )
  );

  
EligibilityEpic.icdSearch = (action$) =>
  action$.pipe(
    ofType(ICD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_icdsearch}/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
EligibilityEpic.icdSave = (action$) =>
  action$.pipe(
    ofType(ICD_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_icdSave}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => icdSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

EligibilityEpic.cptSearch = (action$) =>
  action$.pipe(
    ofType(CPT_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_cptSearch}/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => cptSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
EligibilityEpic.logInfoSave = (action$) =>
  action$.pipe(
    ofType(LOG_INFO_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_loginfoSave}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => logInfoSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.cptSave = (action$) =>
  action$.pipe(
    ofType(CPT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_cptSave}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.eligibilitySave = (action$) =>
  action$.pipe(
    ofType(ELIGIBILITY_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_Save}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => eligibilitySaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.loginInfo = (action$) =>
  action$.pipe(
    ofType(LOGIN_INFO),
    switchMap((action) =>
      UtilsAjax({
        // headers: {
        //   "application-id": 6,
        //   "Content-Type": "application/json",
        // },
        url: `${ClinicBaseUrl}/${Apis.eligibility_Logininfo}?applicationId=${6}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => loginInfoResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.fileUpload = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 6,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        url: `${ClinicBaseUrl}/${Apis.eligibility_fileUpload}/${action.payload.patientId}`,
        method: 'POST',
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.downloadFile = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD),
    switchMap((action) =>
      ajax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_downloadFile}/download?fileId=${action.payload.fileId}&fileName=${action.payload.fileName}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => fileDownloadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

EligibilityEpic.logList = (action$) =>
  action$.pipe(
    ofType(LOGLIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_logList}/${action.payload.appointmentId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => loglistResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

EligibilityEpic.bulkUpdate = (action$) =>
  action$.pipe(
    ofType(BULK_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_bulkUpdate}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.rowUpdate = (action$) =>
  action$.pipe(
    ofType(ROW_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.icdKeywordSearch = (action$) =>
  action$.pipe(
    ofType(ICD_KEYWORD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_icdKeywordSearch}/search?searchField=%20&searchParam=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdkeywordResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

EligibilityEpic.cptDelete = (action$) =>
  action$.pipe(
    ofType(CPT_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_cptDelete}/${action.payload}`,
        method: 'DELETE',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.icdChild = (action$) =>
  action$.pipe(
    ofType(ICD_CHILD),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_icdChild}/search?parentId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdChildResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

EligibilityEpic.paHistory = (action$) =>
  action$.pipe(
    ofType(PA_HISTORY),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_history}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => paHistoryRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
EligibilityEpic.searchPayor = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYOR),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_searchPayor}/name?name=${action.payload.str}&clinicId=${action.payload.id}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchPayoRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
EligibilityEpic.patientSaveApi = (action$) =>
  action$.pipe(
    ofType(PATIENT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_patientSave}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => patientSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.policyHolderSaveApi = (action$) =>
  action$.pipe(
    ofType(POLICYHOLDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_policyHolderSave}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policyHolderSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.policySaveApi = (action$) =>
  action$.pipe(
    ofType(POLICY_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_policySave}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policySaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
EligibilityEpic.mappingListApi = (action$) =>
  action$.pipe(
    ofType(MAPPING_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_mappingList}${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => mappingListRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
EligibilityEpic.saveMappingApi = (action$) =>
  action$.pipe(
    ofType(SAVE_MAPPING),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.eligibility_saveMapping}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => saveMappingRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

export default EligibilityEpic;
