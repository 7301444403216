import React from 'react';
import DataTableContainer from '../DataTableContainer';
import EditContainer from '../EditContainer';
import AppLogout from 'Utils/inactive';
import { useState } from 'react';
function MainContainer(props) {
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState(null);

  return (
    <AppLogout>
      <div hidden={visible}>
        <DataTableContainer
          passRowData={(e) => setRowData(e)}
          visible={(e) => setVisible(e)}
        />
      </div>
      <div hidden={!visible}>
        <EditContainer
          rowData={rowData}
          visible={(e) => setVisible(e)}
          hidden={true}
        />
      </div>
    </AppLogout>
  );
}

export default MainContainer;
