import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from './Containers/NewLogin';
import './GlobalStyles.css';
import { Toaster } from 'react-hot-toast';
import { PrivateRoute } from './Utils/private-route';
import MainContainer from './Containers/MainContainer';
import DataTableContainer from './Containers/DataTableContainer';
import EditContainer from './Containers/EditContainer';

function App() {
  const [rowData, setRowData] = useState(
    JSON.parse(sessionStorage.getItem('rowData')) || null
  );
  const token = localStorage.getItem('accessToken');
  useEffect(() => {
    sessionStorage.setItem('rowData', JSON.stringify(rowData));
  }, [rowData]);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <Switch>
          <Route
            path="/home"
            name="home"
            render={(props) =>
              token ? (
                <DataTableContainer {...props} passRowData={setRowData} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/edit"
            name="edit"
            render={(props) =>
              token ? (
                <EditContainer
                  {...props}
                  setRowData={setRowData}
                  rowData={rowData}
                />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route path={'/login'} name={'login'} component={Login} />
          <Redirect from="/" to="/login" />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
