import React, { useState, useRef, useContext, useEffect } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { dateTommddyyy } from 'HelperFunctions/TimeDateTimestamp';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import { filterContext } from 'context/contextProvider';
import { PatientSearch } from '../../../../../libs/patient-search/src';
import { getCache, searchPatient } from '../../Redux/Appointment/apt.actions';
import { setFilter } from '../../Redux/EligibilityApis/eligibility.actions';
import { getEligibility } from 'Redux/EligibilityApis/eligibility.actions';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import './style.css';

/**
 * @fileOverview Manages Routing.
 * @component
 * @author Akhil Francis <akhil@tensaw.email>
 * @author Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <Header/>
 * )
 */

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '2rem !important',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}));
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: `lightgray`,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: `#139696`,
    },
  },
}));
function Header(props) {
  const classes = useStyles();
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);
  const [forms, setForms] = useState({
    clinic: null,
    provider: null,
    status: [],
    result: [],
    startDate: new Date(),
    endDate: addDays(new Date(), 14),
    payorId: null,
    owner: [],
    dueDate: null,
    patientId: '',
  });
  const [eligibilityData, setEligibilityData] = useState({});
  const [payerName, setPayerName] = useState('');
  const currentPatient=JSON.parse(localStorage.getItem("patientPolicy"))
  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const { headCells, setHeadCells, selectedItem, setSelectedItem } =
    useContext(filterContext);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const handleFilterColumn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const logOutUser = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const handleFilterColumnClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.eligibilityData.filterForm) {
      setForms({
        ...props.eligibilityData.filterForm,
      });
      if (props.eligibilityData.filterForm.clinic) {
        props.getCache(props.eligibilityData.filterForm.clinic);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eligibilityData.filterForm]);

  useEffect(() => {
    let newItem = [];
    Object.keys(selectedItem).forEach((x) => {
      headCells.forEach((j) => {
        if (x === j.id) {
          newItem.push({
            ...j,
            status: selectedItem[x],
          });
        }
      });
    });
    // console.log(newItem);
    setHeadCells(newItem);
    newItem = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleFilterCheckbox = (e) => {
    setSelectedItem({ ...selectedItem, [e.target.name]: e.target.checked });
    let selected = { ...selectedItem, [e.target.name]: e.target.checked };
    // localStorage.setItem('selectedItem', selected);
  };
  const handleBackButton = () => {
    localStorage.setItem('backButtonClicked', Math.random());
  };

  const searchSelect = (i, y) => {
    if (y === null) {
      props.setFilter({ ...forms });
    } else {
      props.setFilter({
        ...forms,
        patientId: y.id,
      });
      // props.eligibilityList({
      //   ...forms,
      //   patientId: y.id,
      // });
    }
  };

  useEffect(() => {
    if (props.eligibilityData.eligibilityInfo) {
      let response = props.eligibilityData.eligibilityInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setEligibilityData(data);
        localStorage.setItem('currentEligibility', JSON.stringify(data));
        const selectedPolicy = data.policyList.find(
          (policy) => policy.ranking === 1
        );
        const payerName = selectedPolicy ? selectedPolicy.payerName : null;
        setPayerName(payerName);
      }
    }
  }, [props.eligibilityData.eligibilityInfo]);

  const sentanceCase = (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  };
  return (
    <div className="clinic__header">
      <div className="clinic__header--inner header-left">
        {props.backButton && (
          <Link to="/home">
            <ArrowBackIcon
              // onClick={() => props.visible(false)}
              onClick={handleBackButton}
              style={{
                marginLeft: '50px',
                fontSize: '1.5rem',
                cursor: 'pointer',
                marginRight: '100px',
              }}
            />
          </Link>
        )}
        {/* {props.prevButton && (
          <Button
            onClick={() => props.handlePrev()}
            variant="outlined"
            color="primary"
            style={{ marginLeft: '50px', marginRight: '50px' }}
            disabled={props.disablePrev}
          >
            <ArrowBackIosIcon style={{ fontSize: '1rem' }} />
            Previous
          </Button>
        )} */}
        <div>
          <ul>
            <li>{props.name ? '' : 'Eligibility Verification'}</li>
          </ul>
        </div>
        {props.backButton && (
          <div className="edit-screen">
            <ul>
              <li>{props.name ? props.name : ''}</li>
            </ul>
            <span>
              {/* {props.rowData ? props.rowData.appointmentTime + ' ' : ''} */}
              {eligibilityData.dos
                ? eligibilityData.dos
                : props.rowData
                ? props.rowData.appointmentTime
                : ''}
            </span>

            <span style={{ marginLeft: '10px' }}>
              {/* {props.rowData
              ? ' | ' +
                props.rowData.patientFName +
                ' ' +
                props.rowData.patientLName
              : ''} */}
              {currentPatient && currentPatient.firstName
                ? ' | ' +
                  sentanceCase(currentPatient.firstName) +
                  ' ' +
                  sentanceCase(currentPatient.middleName) +
                  ' ' +
                  sentanceCase(currentPatient.lastName)
                : props.rowData
                ? ' | ' +
                  props.rowData.patientFName +
                  ' ' +
                  props.rowData.patientLName
                : ''}
            </span>

            <span style={{ marginLeft: '10px' }}>
              {/* {props.rowData && props.rowData.patientDob
              ? ' | ' + dateTommddyyy(props.rowData.patientDob)
              : ''} */}
              {eligibilityData.patient && eligibilityData.patient.sdob
                ? ' | ' + eligibilityData.patient.sdob
                : props.rowData && props.rowData.patientDob
                ? ' | ' + dateTommddyyy(props.rowData.patientDob)
                : ''}
            </span>
            <span style={{ marginLeft: '10px' }}>
              {/* {props.rowData && ' | ' + props.rowData.appointmentType} */}
              {eligibilityData.appointmentType
                ? ' | ' + eligibilityData.appointmentType
                : props.rowData && ' | ' + props.rowData.appointmentType}
            </span>

            <span style={{ marginLeft: '10px' }}>
              {props.rowData ? ' | ' + props.rowData.primaryPayorName : ''}
              {/* {payerName ? ' | ' + payerName : ''} */}
            </span>
            {/* <span>{props.rowInfo.dos}</span> */}
          </div>
        )}
      </div>
      <div className="clinic__header--inner header-right">
        {/* <div className="next-btn">
          {props.nextButton && (
            <Button
              onClick={() => props.handleNext()}
              variant="outlined"
              color="primary"
              style={{ marginRight: '150px' }}
              disabled={props.disableNext}
            >
              Next
              <NavigateNextIcon style={{ fontSize: '1.5rem' }} />
            </Button>
          )}
        </div> */}
        {!props.nextButton && <PatientSearch searchSelect={searchSelect} />}

        {props.name ? (
          ''
        ) : (
          <div className="filter_container">
            <div className="filterColumn_icon">
              <IconButton
                id="column-filter"
                aria-controls={openFilter ? 'column-menu' : undefined}
                aria-expanded={openFilter ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleFilterColumn}
              >
                <FilterIcon />
              </IconButton>
            </div>
            <Menu
              className="columnMenu"
              id="column-menu"
              MenuListProps={{
                'aria-labelledby': 'column-filter',
              }}
              anchorEl={anchorEl}
              open={openFilter}
              onClose={handleFilterColumnClose}
              PaperProps={{
                style: {
                  width: '55ch',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <FormGroup className="column_filter_container">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={0}>
                    {headCells.map((x, i) => (
                      <Grid item xs={4} key={i}>
                        <FormControlLabel
                          className="column_filter_text"
                          control={
                            <GreenCheckbox size="small" color="primary" />
                          }
                          onChange={(e) => handleFilterCheckbox(e)}
                          name={x.id}
                          label={x.label}
                          checked={x.status}
                          labelPlacement="end"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </FormGroup>
            </Menu>
          </div>
        )}
        <div
          className="clinic__profileCircle"
          ref={popoverAnchor}
          aria-owns="mouse-over-popover"
          aria-haspopup="true"
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        >
          <span>EV</span>
        </div>
        <KeyboardArrowDownIcon color="primary" />
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
      >
        <div className="clinicHeader__popover">
          <div className="clinicHeader__popover--name">Eligibility</div>
          <div className="clinicHeader__popover--list">
            <div className="logout" onClick={() => logOutUser()}>
              Logout
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
}

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  getEligibility: (values) => dispatch(getEligibility(values)),
  searchPatient: (values) => dispatch(searchPatient(values)),
  setFilter: (values) => dispatch(setFilter(values)),
  eligibilityList: (values) => dispatch(eligibilityList(values)),
  getCache: (values) => dispatch(getCache(values)),
});
Header.PropTypes = {
  getEligibility: PropTypes.func,
  searchPatient: PropTypes.func,
  setFilter: PropTypes.func,
  eligibilityList: PropTypes.func,
  getCache: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
