import React from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import { ClaimSummaryComponent } from './lib/claim-summary';

function app(props) {
  return (
    <Provider store={store}>
      <ClaimSummaryComponent
        applicationId={props.applicationId}
        rowInfo={props.rowInfo}
        handlePageUpdate={props.handlePageUpdate}
        setRowInfo={props.setRowInfo}
      />
    </Provider>
  );
}

export const ClaimSummary = app;
