import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  setFilter,
  bulkUpdate,
} from 'Redux/EligibilityApis/eligibility.actions';
import { getCache } from 'Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import DobPicker from 'Components/dobPicker';
import Dropdown from 'Components/Dropdown';
import { useEffect } from 'react';
import './styles.css';

/**
 * @fileOverview Manages Routing.
 * @component
 * @author Akhil Francis <akhil@tensaw.email>
 * @author Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <Header/>
 * )
 */

const DataTableFilter = (props) => {
  const [clinicList, setClinicList] = useState([]);
  const [provider, setProvider] = useState([]);
  const [results, setResults] = useState([]);
  const [checkBoxDto, setCheckboxDto] = useState([]);
  const [payorList, setPayorList] = useState([]);
  const [forms, setForms] = useState({
    clinic: null,
    provider: null,
    result: null,
    startDate: new Date(),
    endDate: new Date(),
    payorId: null,
    // dueDate: null,
  });
  const [actionForm, setActionForm] = useState({
    result: null,
  });

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let clinicList = [];
    let results = [];
    cache.clinicDtoList.forEach((x) => {
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });
    cache.resultDtoList.forEach((x) => {
      results.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.payorList.forEach((x) => {
      payorList.push({
        name: x.payerName,
        value: x.id,
      });
    });
    setClinicList(clinicList);
    setResults(results);
    setPayorList(payorList);
  }, []);

  useEffect(() => {
    if (props.checkBoxArray && props.checkBoxArray.length > 0) {
      let a = [];
      props.checkBoxArray.forEach((x) => {
        if (x.isSelected === true) {
          a.push({
            appointmentId: x.appointmentId,
            insuranceId: x.insuranceId,
            resultId: x.resultId,
          });
        }
      });
      setCheckboxDto(a);
    }
  }, [props.checkBoxArray]);

  const handleCheckbox = () => {
    let a = [];
    checkBoxDto.forEach((x) => {
      a.push({
        appointmentId: x.appointmentId,
        insuranceId: x.insuranceId,
        resultId: actionForm.result,
      });
    });
    props.bulkUpdate(a);
    setCheckboxDto('');
    // setActionForm('')
  };

  useEffect(() => {
    if (props.AppointmentData.cache) {
      let Response = props.AppointmentData.cache.response;
      if (Response.responseCode === 0) {
        let data = Response.data;
        let provider = [];
        data.providerDtoList.forEach((x) => {
          provider.push({
            name: x.fullName,
            value: x.id,
          });
        });
        if (forms.clinic) {
          setProvider(provider);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.cache]);

  useEffect(() => {
    let f = props.eligibilityData.filterForm;
    // console.log(f);
    if (f) {
      setForms({
        clinic: f.clinic,
        provider: f.provider,
        result: f.result,
        startDate: f.startDate ? f.startDate : new Date(),
        endDate: f.endDate ? f.endDate : new Date(),
        payorId: f.payorId,
      });
    }
  }, [props.eligibilityData.filterForm]);

  return (
    <div>
      <div className="filter_container_div">
        <div className="filter_field">
          <Dropdown
            tabIndex={3}
            options={[{ name: 'All Clinics', value: null }, ...clinicList]}
            label="Clinic"
            onChange={(e) => {
              setForms({ ...forms, clinic: e.target.value });
              if (e.target.value) {
                props.getCache(e.target.value);
              }
            }}
            value={forms.clinic ? forms.clinic : ''}
          />
        </div>
        <div className="filter_field">
          <Dropdown
            options={[{ name: 'All Provider', value: null }, ...provider]}
            label="Provider"
            onChange={(e) => setForms({ ...forms, provider: e.target.value })}
            value={forms.provider ? forms.provider : ''}
          />
        </div>
        <div className="filter_field">
          <Dropdown
            tabIndex={3}
            options={[{ name: 'All Results', value: null }, ...results]}
            label="Result"
            onChange={(e) => setForms({ ...forms, result: e.target.value })}
            value={forms.result ? forms.result : ''}
          />
        </div>
        <div className="filter_field_date">
          <DobPicker
            label="DOS Start"
            inputVariant={'standard'}
            onChange={(e) => setForms({ ...forms, startDate: e.target.value })}
            value={forms.startDate}
          />
        </div>
        <div className="filter_field_date">
          <DobPicker
            label="DOS End"
            inputVariant={'standard'}
            onChange={(e) => setForms({ ...forms, endDate: e.target.value })}
            value={forms.endDate}
            minDate={forms.startDate}
          />
        </div>
        <div className="filter_field_date">
          <Dropdown
            tabIndex={3}
            options={[{ name: 'All Payor', value: null }, ...payorList]}
            label="Payor"
            value={forms.payorId || ''}
            onChange={(e) => setForms({ ...forms, payorId: e.target.value })}
          />
        </div>
        <div className="filter_field_btn filter_btn">
          <Button
            variant="contained"
            color="primary"
            onClick={() => props.setFilter(forms)}
          >
            Filter
          </Button>
        </div>

        <div className="filter_field">
          <Dropdown
            tabIndex={3}
            options={[{ name: 'All Results', value: null }, ...results]}
            label="Result"
            value={actionForm.result}
            onChange={(e) =>
              setActionForm({ ...actionForm, result: e.target.value })
            }
          />
        </div>

        <div className="filter_field_btn">
          <Button
            disabled={checkBoxDto.length > 0 ? false : true}
            variant="contained"
            color="primary"
            onClick={handleCheckbox}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  eligibilityData: state.eligibility,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (values) => dispatch(setFilter(values)),
  bulkUpdate: (values) => dispatch(bulkUpdate(values)),
  getCache: (values) => dispatch(getCache(values)),
});
DataTableFilter.propTypes = {
  setFilter: PropTypes.func,
  bulkUpdate: PropTypes.func,
  getCache: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(DataTableFilter);
