import { combineEpics } from "redux-observable";
import EligibilityEpic from "./EligibilityApis/eligibility.epic";
import AppointmentEpic from "./Appointment/apt.epic";

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  EligibilityEpic.login,
  EligibilityEpic.eligibilityList,
  EligibilityEpic.getEligibility,
  EligibilityEpic.icdSearch,
  EligibilityEpic.cptSearch,
  EligibilityEpic.logInfoSave,
  EligibilityEpic.icdSave,
  EligibilityEpic.cptSave,
  EligibilityEpic.eligibilitySave,
  EligibilityEpic.loginInfo,
  EligibilityEpic.fileUpload,
  EligibilityEpic.downloadFile,
  EligibilityEpic.logList,
  EligibilityEpic.bulkUpdate,
  EligibilityEpic.rowUpdate,
  EligibilityEpic.icdKeywordSearch,
  EligibilityEpic.cptDelete,
  EligibilityEpic.icdChild,
  EligibilityEpic.paHistory,
  EligibilityEpic.searchPayor,
  EligibilityEpic.patientSaveApi,
  EligibilityEpic.policySaveApi,
  EligibilityEpic.policyHolderSaveApi,
  EligibilityEpic.mappingListApi,
  EligibilityEpic.saveMappingApi,
  AppointmentEpic.searchPatientApi,
  AppointmentEpic.cacheApi,
  AppointmentEpic.AppointmentSaveApi,
  AppointmentEpic.payerSaveApi,
  AppointmentEpic.providerSaveApi,
  AppointmentEpic.providerListApi,
);
export default rootEpic;
